import {
    ENDPOINTS,
    FIXED_FOLDER_IDS,
    MUTATION_KEYS,
    QUERY_KEYS,
    UPLOAD_RESET_TIMEOUT_MS,
} from '@/app/mediaLibrary/constants';

import { useMutation, useQueryClient } from '@tanstack/react-query';
import axios from 'axios';
import { useState } from 'react';

import { apiPost } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { reportMessage } from '@/utils/sentry';

import type { ImageResource, MediaType } from '@/app/mediaLibrary/types';
import type { ResponseData } from '@/core/api/types';

const createMediaPayload = ({
    mediaId,
    workspaceId,
    folderId,
    fileName,
    type,
}: {
    mediaId: string;
    workspaceId: string;
    folderId: string;
    fileName: string;
    type: MediaType;
}) => {
    const payload = {
        data: {
            type,
            id: mediaId,
            attributes: {
                filename: fileName,
            },
            relationships: {
                workspace: {
                    data: {
                        type: 'workspace',
                        id: workspaceId,
                    },
                },
                folder: {
                    data: {
                        type: 'folder',
                        id: folderId,
                    },
                },
            },
        },
    };

    if (folderId === FIXED_FOLDER_IDS.all) {
        // remove folder from payload
        delete payload.data.relationships.folder;
    }

    return payload;
};

export const useUploadMedia = () => {
    const queryClient = useQueryClient();

    const [progress, setProgress] = useState(0);
    const [uploadedCount, setUploadedCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);

    const reset = () => {
        setProgress(0);
        setUploadedCount(0);
        setTotalCount(0);
    };

    const mutation = useMutation({
        mutationKey: MUTATION_KEYS.uploadImages(),
        mutationFn: async ({
            folderId,
            files,
            workspaceId,
            type,
        }: {
            folderId: string;
            files: File[];
            workspaceId: string;
            type: MediaType;
            successCb: (uploadedMedia: ImageResource[]) => void;
        }) => {
            setTotalCount(files.length);
            const uploadedMedia: ImageResource[] = [];

            for (const [index, file] of files.entries()) {
                // 1. Get signed URL
                const signedUrlResponse = await apiPost<
                    ResponseData<{ putUrl: string; mediaId: string }>
                >(ENDPOINTS.POST.generateUploadUrl(), {
                    data: {
                        format: file.name.split('.').pop(),
                    },
                });

                const signedUrl = getDataFromResponse(signedUrlResponse)?.putUrl;
                const mediaId = getDataFromResponse(signedUrlResponse)?.mediaId;

                // 2. Upload to signed URL
                const uploadResponse = await axios.put(signedUrl, file, {
                    headers: { 'Content-Type': file.type },
                });

                if (uploadResponse.status !== 200) {
                    throw new Error(
                        `Upload to signed URL failed with status ${uploadResponse.status}`,
                    );
                }

                // 3. Create media resource only after upload was successful
                const mediaResourceResponse = await apiPost<ResponseData<ImageResource>>(
                    ENDPOINTS.POST.createMediaResource(),
                    createMediaPayload({
                        mediaId,
                        workspaceId,
                        folderId,
                        fileName: file.name,
                        type,
                    }),
                );

                setUploadedCount(index + 1);
                setProgress((index + 1) / files.length);

                const media = getDataFromResponse(mediaResourceResponse);

                uploadedMedia.push(media);
            }

            return uploadedMedia;
        },
        onSuccess: async (data, variables) => {
            const { folderId, successCb } = variables;

            if (successCb) {
                successCb(data);
            }

            await queryClient.invalidateQueries({
                queryKey: QUERY_KEYS.folderImages(folderId),
            });

            // Also invalidate "All images"
            if (folderId !== FIXED_FOLDER_IDS.all) {
                await queryClient.invalidateQueries({
                    queryKey: QUERY_KEYS.folderImages(FIXED_FOLDER_IDS.all),
                });
            }

            setTimeout(() => {
                reset();
            }, UPLOAD_RESET_TIMEOUT_MS);
        },
        onError: (err) => {
            reset();
            reportMessage({
                message: `Failed to upload images: ${err.message}`,
                source: 'image-library',
                severityLevel: 'warning',
            });
        },
    });

    return { ...mutation, progress, totalCount, uploadedCount };
};
