import type { AnalyticsKey, Column, DateRangePickerStaticRangeKey } from './types';

export const NAME = 'analytics';

export const DEFAULT_PAGE_SIZE = 20;

export const LS_DELETE_SESSION_REMINDER_KEY = 'session-deletion-reminder';

export const SPLIT_TESTING_BANNER_KEY = 'split-testing-banner-seen-';

export const MOCK_SERVER_ENABLED_KEY = 'mock-server-enabled';

export const SPLIT_TESTING_MODAL_KEY = 'split-testing-intro-seen';

export const SPLIT_TESTING_HELP_CENTER_LINK =
    'https://intercom.help/perspective-funnels/de/articles/6019383-wie-kann-ich-a-b-testing-in-meinen-funnels-nutzen';

export const ANALYTICS_KEYS: { [key: string]: AnalyticsKey } = {
    pages: 'pages',
    kpis: 'kpis',
    responses: 'responses',
    completionRate: 'completion-rate',
    variants: 'variants',
};

export const TABS = {
    analytics: 'dashboard',
    insights: 'insights',
    contacts: 'contacts',
    sessions: 'sessions',
};

export const NAVBAR_HEIGHT = 64;

export const FILE_UPLOAD_URL = 'uploads.leadnotifications.co/';

export const SESSION_TABLE_DEFAULT_COLUMNS: Column[] = [
    {
        name: 'createdAt',
        translation: 'column createdAt',
        path: 'attributes.createdAt',
        clickable: true,
        sortable: true,
    },
    {
        name: 'profile.email',
        translation: 'column email',
        path: 'answers.email',
        clickable: true,
        sortable: true,
    },
];

export const TABLE_ROW_HEIGHT_PX = 53;
export const TABLE_PADDING_PX = 155;

export const DOT_FIELD_NAMES_MAP = {
    'address.city': 'addressCity',
    'address.country': 'addressCountry',
    'address.postalCode': 'addressPostalCode',
    'address.street': 'addressStreet',
    'address.state': 'addressState',
    'company.name': 'companyName',
    'company.industry': 'addressIndustry',
    'company.employee_count': 'addressEmployeeCount',
    'company.plan': 'addressPlan',
};

export const DOT_FIELD_NAMES = Object.keys(DOT_FIELD_NAMES_MAP);

export const DATEPICKER_STATIC_RANGES_KEYS: { [key: string]: DateRangePickerStaticRangeKey } = {
    CUSTOM: 'custom',
    SINCE_CREATION: 'sinceCreation',
    SINCE_SPLIT_TEST: 'sinceSplitTest',
    TODAY: 'today',
    YESTERDAY: 'yesterday',
    LAST_WEEK: 'lastWeek',
    LAST_MONTH: 'lastMonth',
    LAST_QUARTER: 'lastQuarter',
    LAST_HALF: 'lastHalf',
    LAST_YEAR: 'lastYear',
};

export const INTL_DATE_FORMAT = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
};

export const ISO_DATE_REGEX = /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2})\.(\d{3})?Z$/;

export const HIDDEN_FIELDNAMES = [
    'fbclid',
    'ps_fist_seen_at', // typo was made and now is tracked
    'ps_first_seen_at',
    'ps_end_slug',
    'ps_converted_at',
    'ps_completed_at',
    'ps_closed_at',
    'ps_last_seen_at',
    'ps_converted',
    'ps_completed',
    'ps_closed',
    'ps_embed',
];
