import { createSlice } from '@reduxjs/toolkit';
import isNil from 'lodash/isNil';
import omitBy from 'lodash/omitBy';
import Router from 'next/router';

import { getCrmUrl } from '@/app/crm/helpers';
import { showModal, hideModal } from '@/app/modals/models/modals';
import { Modals } from '@/app/modals/types';
import { domainAvailabilityRequest } from '@/app/workspaces/helpers/apis';
import { createWorkspaceRequest } from '@/app/workspaces/helpers/apis';
import { getWorkspaceInfoFormData } from '@/app/workspaces/helpers/utils';
import { SetUpSteps, RequestState, DomainAvaibilityStatus } from '@/app/workspaces/types';
import { handleRuntimeError } from '@/core/api';
import { getDataFromResponse } from '@/core/api/helper';
import { EMPTY_OBJECT } from '@/utils/empty';

import { setWorkspace, setActiveWorkspaceId, fetchAllWorkspaces } from './workspaces';
import { NAME } from '../constants';

import type {
    NewWorkspaceFormData,
    WorkspaceResource,
    WorkspaceInfoFields,
} from '@/app/workspaces/types';
import type { AppState, AppThunk } from '@/core/redux/types';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AxiosError } from 'axios';

type RequestType = 'createWorkspace' | 'domainAvailability' | 'sendInvites';

interface State {
    currentStep: number;
    setupData: NewWorkspaceFormData;
    requestState: Partial<Record<RequestType, RequestState>>;
    domainAvailable: boolean;
    workspace?: WorkspaceResource;
}

const initialState: State = {
    currentStep: SetUpSteps.Intro,
    setupData: EMPTY_OBJECT,
    requestState: {
        createWorkspace: RequestState.Idle,
        domainAvailability: RequestState.Idle,
        sendInvites: RequestState.Idle,
    },
    domainAvailable: false,
    workspace: undefined,
};

export const setupSlice = createSlice({
    name: `${NAME}/setup`,
    initialState,
    reducers: {
        nextStep(state) {
            state.currentStep++;
        },
        prevStep(state) {
            state.currentStep--;
        },
        setSetupData(state, action: PayloadAction<NewWorkspaceFormData>) {
            state.setupData = {
                ...state.setupData,
                ...action.payload,
            };
        },
        setRequestState(state, action: PayloadAction<State['requestState']>) {
            state.requestState = {
                ...state.requestState,
                ...action.payload,
            };
        },
        setDomainAvailable(state, action: PayloadAction<boolean>) {
            state.domainAvailable = action.payload;
        },
        setNewWorkspace(state, action: PayloadAction<WorkspaceResource>) {
            state.workspace = action.payload;
        },
        reset: () => initialState,
    },
});

// === Actions ======

export const {
    nextStep,
    prevStep,
    setSetupData,
    setRequestState,
    setDomainAvailable,
    setNewWorkspace,
    reset,
} = setupSlice.actions;

// === Selectors ======

export const getCurrentSignupStep = (state: AppState) => state[NAME].setupReducer.currentStep;
export const getSetupData = (state: AppState) => state[NAME].setupReducer.setupData;
export const getRequestState = (state: AppState) => state[NAME].setupReducer.requestState;
export const getDomainAvailable = (state: AppState) => state[NAME].setupReducer.domainAvailable;
export const getWorkspace = (state: AppState) => state[NAME].setupReducer.workspace;

// === Helpers ======

export const getExitModalText = (currentStep: SetUpSteps) => {
    switch (currentStep) {
        case SetUpSteps.Domain:
            return {
                titleTranslationKey: 'setup-steps-exit-dialog-cancel-setup-title',
                descriptionTranslationKey: 'setup-steps-exit-dialog-cancel-setup-description',
            };
        case SetUpSteps.MoveFunnelsToWorkspace:
        case SetUpSteps.InviteUser:
            return {
                titleTranslationKey: 'setup-steps-exit-dialog-incomplete-setup-title',
                descriptionTranslationKey: 'setup-steps-exit-dialog-incomplete-setup-description',
            };
        default:
            return {
                titleTranslationKey: '',
                descriptionTranslationKey: '',
            };
    }
};

// === Thunk ======

export const completeSetup =
    (funnelId?: string): AppThunk =>
    (_, getState) => {
        if (funnelId) {
            Router.replace(getCrmUrl(funnelId));

            return;
        }

        const workspace = getWorkspace(getState());

        Router.replace(`/workspaces/${workspace.id}`);
    };

export const exitSetup =
    (funnelId?: string): AppThunk =>
    (dispatch, getState) => {
        const currentStep = getCurrentSignupStep(getState());

        if (currentStep === SetUpSteps.Intro || currentStep === SetUpSteps.Info) {
            Router.replace('/workspaces', undefined, {
                shallow: true,
            });

            return;
        }

        if (currentStep === SetUpSteps.SetupFinish) {
            dispatch(completeSetup(funnelId));

            return;
        }

        dispatch(
            showModal(Modals.WORKSPACE_SETUP_EXIT, {
                ...getExitModalText(currentStep),
                onContinue: () => {
                    dispatch(hideModal());
                },
                onCancel: () => {
                    if (currentStep === SetUpSteps.Domain) {
                        Router.replace('/workspaces', undefined, {
                            shallow: true,
                        });

                        return;
                    }

                    dispatch(completeSetup(funnelId));
                },
            }),
        );
    };

export const goBack =
    (funnelId?: string): AppThunk =>
    (dispatch, getState) => {
        const currentStep = getCurrentSignupStep(getState());

        if (currentStep === SetUpSteps.Info || currentStep === SetUpSteps.Domain) {
            dispatch(prevStep());

            return;
        }

        dispatch(exitSetup(funnelId));
    };

export const setupWorkspace =
    (values: {
        [WorkspaceInfoFields.Domain]: string;
        [WorkspaceInfoFields.Name]: string;
        [WorkspaceInfoFields.Logo]: File;
    }): AppThunk<Promise<WorkspaceResource>> =>
    async (dispatch) => {
        const cleanData = omitBy(values, isNil);

        dispatch(
            setRequestState({
                createWorkspace: RequestState.InProgress,
            }),
        );

        try {
            const formData = getWorkspaceInfoFormData(cleanData);
            const response = await createWorkspaceRequest(formData);
            const workspace = getDataFromResponse(response);

            dispatch(
                setRequestState({
                    createWorkspace: RequestState.Success,
                }),
            );

            dispatch(setWorkspace(workspace));
            dispatch(setNewWorkspace(workspace));

            await dispatch(fetchAllWorkspaces());

            dispatch(setActiveWorkspaceId(workspace.id));

            return workspace;
        } catch (err) {
            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'creating workspace failed:',
            });

            dispatch(
                setRequestState({
                    createWorkspace: RequestState.Error,
                }),
            );
        }
    };

export const domainAvailability =
    (domain: string): AppThunk<Promise<DomainAvaibilityStatus | void>> =>
    async (dispatch) => {
        dispatch(
            setRequestState({
                domainAvailability: RequestState.InProgress,
            }),
        );

        try {
            const data = getDataFromResponse(await domainAvailabilityRequest(domain));

            dispatch(setDomainAvailable(!!data?.available));
            dispatch(
                setRequestState({
                    domainAvailability: RequestState.Success,
                }),
            );

            return data?.available
                ? DomainAvaibilityStatus.Available
                : DomainAvaibilityStatus.NotAvailable;
        } catch (err) {
            const error = err as AxiosError<{ message: string }>;
            const status = error?.response?.status;

            dispatch(
                setRequestState({
                    domainAvailability: RequestState.Error,
                }),
            );

            if (status === 400) {
                dispatch(setDomainAvailable(false));

                return DomainAvaibilityStatus.Invalid;
            }

            handleRuntimeError(err as AxiosError<{ message: string }>, {
                debugMessage: 'error validating domain',
            });
        }
    };

export default setupSlice.reducer;
